import React from 'react'
import { Helmet } from 'react-helmet'

interface InstaFeedProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_InstaFeed
  location?: any
}

const InstaFeed: React.FC<InstaFeedProps> = ({ fields }) => (
  <>
    <Helmet>
      <script src="https://apps.elfsight.com/p/platform.js" defer />
    </Helmet>
    <section id="instagramFeed" className="container mb-5 pb-5">
      <div className="row px-2">
        <div className="col-12">
          {fields.elfsight ? (
            <div className={fields?.elfsight} />
          ) : (
            <div>Instagram feed niet gevonden...</div>
          )}
        </div>
      </div>
    </section>
  </>
)

export default InstaFeed
